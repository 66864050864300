<template>
  <div class="main-panel" v-loading="pageLoading">
    <h3 class="panel-title" v-if="!layerInfo?.id">用户资料</h3>
    <el-button type="danger"
               size="mini"
               round
               class="save"
               :class="{'update-btn': layerInfo?.id}"
               :loading="isLoading"
               @click="save">保存
    </el-button>
    <el-form :model="form"
             :rules="formRules"
             ref="userForm"
             label-width="65px"
             @submit.prevent>
      <el-row :gutter="16">
        <el-col :span="12">
          <el-form-item label="用户名" prop="username">
            <el-input v-model.lazy.trim="form.username" :size="controlSize" placeholder="用户名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model.trim="form.password" :size="controlSize" :placeholder="this.layerInfo?.id ? '修改密码时需填写' : '密码'"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="16">
        <el-col :span="12">
          <el-form-item label="个人名" prop="nickName">
            <el-input v-model.lazy.trim="form.nickName" :size="controlSize" placeholder="个人名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model.trim="form.mobile"
                      :size="controlSize"
                      placeholder="手机号"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      maxlength="11"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="职位" prop="position">
        <el-radio-group v-model="form.position">
          <el-radio label="">无</el-radio>
          <el-radio label="管理员">管理员</el-radio>
          <el-radio label="销售员">销售员</el-radio>
          <el-radio label="生产跟单员">生产跟单员</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="部门" prop="position" v-if="form.position === '生产跟单员'">
        <el-select :size="controlSize" multiple filterable v-model="form.departmentIds" placeholder="请选择部门">
          <el-option v-for="item in departments" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="绑定通知消息" label-width="100px">
        <el-checkbox-group v-model="form.bindMessage">
          <el-checkbox :label="1">来胚</el-checkbox>
          <el-checkbox :label="2">生产单</el-checkbox>
          <el-checkbox :label="3">已发货</el-checkbox>
          <el-checkbox :label="4">改色号</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    layerInfo: {
      type: Object,
      default: () => {
      }
    }
  },

  data() {
    const validatePass = (rule, value, callback) => {
      if (!this.layerInfo?.id && value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };

    return {
      controlSize: "mini",
      form: {
        username: "",
        password: "",
        nickName: "",
        mobile: "",
        position: "",
        departmentIds: [],
        bindMessage: []
      },
      originDepartmentIds: [],

      formRules: {
        username: [{required: true, message: "请填写用户名", trigger: "blur"}],
        password: [{required: !this.layerInfo?.id, validator: validatePass, trigger: "blur"}],
        nickName: [{required: true, message: "请填写个人名", trigger: "blur"}],
        mobile: [
          {required: true, message: "请填写手机号", trigger: "blur"},
          {min: 11, max: 11, message: "手机号长度不正确", trigger: "blur"}
        ]
      },

      departments: [],

      pageLoading: false,
      isLoading: false
    }
  },

  watch: {
    "form.position": {
      handler: async function (val) {
        if (val === "生产跟单员" && !this.departments.length) {
          this.pageLoading = true
          this.departments = await this.$getList.getDepartments()
          this.pageLoading = false
        } else {
          this.form.departmentIds = []
        }
      },
      immediate: true
    }
  },

  mounted() {
    this.layerInfo?.id && this.getDetail()
  },

  methods: {
    getDetail() {
      const detail = this.layerInfo
      this.form = {
        username: detail.username,
        nickName: detail.name,
        mobile: detail.phone,
        position: detail.position || "",
        bindMessage: detail.messageId.split(",").map(i => +i)
      }
      if (detail.position === "生产跟单员") {
        this.form.departmentIds = detail.userDepts.map(dept => dept.deptId) || []
        this.originDepartmentIds = detail.userDepts
      }
    },

    save() {
      this.$refs["userForm"].validate(async valid => {
        if (valid) {
          this.isLoading = true
          this.originDepartmentIds = this.originDepartmentIds.map(item => ({...item, delflag: Number(this.form.departmentIds.includes(item.deptId))}))
          // console.log(this.originDepartmentIds)
          const originDepartmentIds = this.originDepartmentIds.map(item => item.deptId)
          const departments = this.form.departmentIds
              .filter(item => !originDepartmentIds.includes(item))
              .map(item => ({deptId: item}))
              .concat(this.originDepartmentIds)
          // console.log(departments)

          const sendData = {
            username: this.form.username,
            password: this.form.password,
            staffname: this.form.nickName,
            phone: this.form.mobile,
            position: this.form.position,
            departments,
            messageIds: this.form.bindMessage
          }
          !sendData.password && Reflect.deleteProperty(sendData, "password");

          if (this.layerInfo?.id) {
            sendData.id = this.layerInfo.id
            const res = await this.$api.Base.User.update(sendData).catch(() => {})
            this.isLoading = false

            if (res?.code === 200) {
              this.$message.success("修改成功")
              this.$emit("handle")
            } else {
              this.$message.error(res?.msg || "修改失败")
            }
          } else {
            const res = await this.$api.Base.User.add(sendData).catch(() => {})
            this.isLoading = false

            if (res?.code === 200) {
              this.$message.success("新增成功")
              this.$refs["userForm"].resetFields()
              this.form.bindMessage = []
            } else {
              this.$message.error(res?.msg || "新增失败")
            }
          }
        }
      })
    }
  }
}
</script>
